import request from './request'
import { uuid } from 'vue-uuid'

const Customer = {
  viewProfile() {
    return request({
      url: '/v1/customer/viewprofile',
      method: 'get'
    })
  },
  updateProfile(data) {
    data.idempotency = uuid.v1()
    return request({
      url: '/v1/customer/viewprofile',
      method: 'post',
      data: data
    })
  },
  resetPassword(data){
    data.idempotency = uuid.v1()
    return request({
      url : '/v1/customer/resetpassword',
      method: 'post',
      data: data
    })
  },
  account(){
    return request({
      url : '/v1/account',
      method: 'post',
    })
  },
  renewPassportNotification() { 
    return request({
      url: "/v1/customer/renewpassport/notification",
      method: 'post'
    });
  }
}

export default Customer