<template>
  <div>
    <div :class="`container-fluid ${userplus ? 'mt--6' : 'mt--5' } `">
      <div class="row">
        <div :class="` ${userplus ? 'col-xl-9' : '' } col-md-12`">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Change Your Password</h3>
            </div>

            <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="col-xl-7 col-lg-8 col-md-12">
                  <el-form
                    ref="formPassword"
                    :model="form"
                    :rules="formRules"
                    :label-position="labelPosition"
                    label-width="150px"
                  >
                    <el-form-item
                      label="Current Password"
                      prop="current_password"
                    >
                      <el-input
                        type="password"
                        v-model="form.current_password"
                        class="form-control-alternative"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="New Password" prop="new_password">
                      <el-input
                        type="password"
                        v-model="form.new_password"
                        class="form-control-alternative"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="Confirm Password"
                      prop="confirm_password"
                    >
                      <el-input
                        type="password"
                        v-model="form.confirm_password"
                        class="form-control-alternative"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row justify-content-xl-center">
                <div class="col-xl-12 text-center">
                  <el-button @click="resetForm()">Cancel</el-button>
                  <el-button
                    type="primary"
                    v-loading="submiting"
                    :disabled="submiting"
                    @click="onSubmit()"
                    >Confirm</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12" v-if="userplus">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>
  </div>
</template>
<script>
import CustomerApi from "@/api/customer";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components: {
    PinDialog,
    Shortcut
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
    userplus(){
      return this.$store.getters.usertype == 'plus'
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password"));
      } else {
        if (this.form.confirm_password !== "") {
          this.$refs.formPassword.validateField("confirm_password");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password again"));
      } else if (value !== this.form.new_password) {
        callback(new Error("Confirm password don't match!"));
      } else {
        callback();
      }
    };

    return {
      submiting: false,
      isPincode: false,
      form: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      formRules: {
        current_password: [
          {
            required: true,
            message: "Please enter the currenct password",
            trigger: "blur",
          },
        ],
        new_password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        confirm_password: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.resetPassword()
      }
    },
    resetPassword() {
      this.submiting = true;

      if(!this.isPincode && this.isMobile){
        this.$refs.pincodedialog.show('password-change')
        return false;
      }

      CustomerApi.resetPassword(this.form).then(({ result, data, message }) => {
        this.submiting = false;
        if (result) {
          if (data.scapending) {
            this.socketSubscription();
          } else {
            this.$swal(`Message`, message, "success").then(() => {
              this.resetForm();
              this.logout();
            });
          }
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push(`/login`);
      });
    },
    resetForm() {
      this.$refs.formPassword.resetFields();
    },
    onSubmit() {
      this.$refs.formPassword.validate((valid) => {
        if (valid) {
          this.resetPassword();
        }
      });
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Change password Pending",
        "Please confirm change password from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("password-change:" + this.plusuid, (data) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (data.result) {
          this.$swal.fire({
            icon: "success",
            title: "Change password is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.resetForm();
            this.logout();
          }, 2000);
        } else {
          this.$swal("Message", data.message, "error");
        }
      });
    },
  },
};
</script>
<style></style>
